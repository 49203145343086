import React, { useState, useEffect } from "react";
import "./Card.css";
import "react-circular-progressbar/dist/styles.css";
import { motion, AnimateSharedLayout } from "framer-motion";
import {UilTimes, UilPackage} from "@iconscout/react-unicons"
import Chart from "react-apexcharts";
import { enqueueSnackbar } from 'notistack';
import {ErrorMsgs} from '../../../../Data/Data'
import axios from '../../../../api/axios';


const EXPIRINGKEYS_URL = process.env.REACT_APP_API_GET_EXPIRING_KEYS_NEXT_MONTH;
// parent Card

const TwoRowsCard = (props) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <AnimateSharedLayout>
      {expanded ? (
        <ExpandedCard param={props} setExpanded={() => setExpanded(false)} />
      ) : (
        <CompactCard param={props} setExpanded={() => setExpanded(true)} />
      )}
    </AnimateSharedLayout>
  );
};

// Compact Card
function CompactCard({param, setExpanded }) {
  return (
    <motion.div
    className={param.color === 'purple'? "CompactCard purpleCard" :"CompactCard pinkCard"}
      layoutId="expandableCard"
      onClick={setExpanded}
    >
      <div className="title">
        <span>{param.title}</span>         
        <span><param.icon /></span>
      </div>
      
      <div className="contents">
        <div className="radialBar">
          <span>{(param.headingOne)}</span>
          <span>{(param.headingTwo)}</span>
        </div>
        <div className="detail">
          <span>{(param.rowOne)}</span>
          <span>{(param.rowTwo)}</span>
        </div>
      </div>
    </motion.div>
  );
}

// Expanded Card
const currentMonth = new Date().getMonth(); 
const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

function ExpandedCard({param, setExpanded }) {

  let variant

  const [expiringKeys, setExpiringKeys] = useState([{ index : 0,expDate: '', customerName: '', moblieNo: '',tpNo:''}])
  const [expiringKeysCount, setExpiringKeysCount] = useState(0); 
  const lakingKeys = 0;

  const availableData = Array(currentMonth + 1).fill(0);
  availableData[currentMonth] = param.available;
  const usedData = Array(currentMonth + 1).fill(0);
  usedData[currentMonth] = param.used;
  const expiringKeysData = Array(currentMonth + 1).fill(0);
  expiringKeysData[currentMonth] = expiringKeysCount; 
  const categories = monthNames.slice(0, currentMonth + 1);
 


  const [data, setData] = useState ({
    series: [
       {
        name: "Expiring Keys",
        data: expiringKeysData,
      },
      
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        height: "auto",
      },
      colors: ["#d1c4e9"],

      dropShadow: {
        enabled: false,
        enabledOnSeries: undefined,
        top: 0,
        left: 0,
        blur: 3,
        color: "#000",
        opacity: 0.35,
      },

      fill: {
        colors: ["#d1c4e9", '#f3e5f5'],
        type: "gradient",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        colors: ["white"],
      },
      grid: {
        show: true,
      },
      xaxis: {
        categories: categories,
        tickAmount: categories.length,
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            // Display integer values without thousand separators
            return Math.floor(val).toString();
          },
        },
       // tickAmount: 6, // Set the number of ticks you want
       min: 0, // Set the minimum value of the y-axis
        max: 50, // Set the maximum value of the y-axis
      },
    },
  });

  useEffect(() => {
    const fetchExpiringKeys = async () => {
      try {
        const response = await axios.get(EXPIRINGKEYS_URL);
        const status = response.data.status;
        const result = response.data.result;
        const msg = response.data.msg;
        if (status) {
          setExpiringKeys(result);
          setExpiringKeysCount(result.length);

          // Create a copy of the expiringKeysData array and update the count
          const updatedExpiringKeysData = [...expiringKeysData];
          updatedExpiringKeysData[currentMonth] = result.length;

          // Update the data object with the updated expiringKeysData
          const updatedData = {
            ...data,
            series: [
              {
                ...data.series[0],
                data: updatedExpiringKeysData,
              },
              ...data.series.slice(1),
            ],
          };

          setData(updatedData); // Update the data state with the new object
        } else {
          setExpiringKeys([]);
          setExpiringKeysCount(0);

          // Reset the expiringKeysData array
          const resetExpiringKeysData = Array(currentMonth + 1).fill(0);
          resetExpiringKeysData[currentMonth] = 0;

          // Update the data object with the reset expiringKeysData
          const updatedData = {
            ...data,
            series: [
              {
                ...data.series[0],
                data: resetExpiringKeysData,
              },
              ...data.series.slice(1),
            ],
          };

          setData(updatedData); // Update the data state with the new object

          variant = "error";
          enqueueSnackbar(msg, { variant });
        }
      } catch (err) {
        variant = "error";
        if (!err.response) {
          enqueueSnackbar(ErrorMsgs[0].noResponse, { variant });
        } else if (err.response !== 403) {
          enqueueSnackbar(ErrorMsgs[0].fetchError, { variant });
        }
      }
    };
    fetchExpiringKeys();
  }, []);
  
/* useEffect(() => {
  setExpiringKeysCount(expiringKeys.length);
}, [expiringKeys]); */

  return (
    <motion.div
      className={
        param.color === "purple" ? "ExpandedCard purpleCard" : "ExpandedCard pinkCard"
      }
      layoutId="expandableCard"
    >
      <div style={{ alignSelf: "flex-end", cursor: "pointer", color: "black" }}>
        <UilTimes onClick={setExpanded} />
      </div>
      <span>{param.title}</span>
      <div className="chartContainer">
        {/* Integrate the chart here */}
        <Chart
          options={data.options}
          series={data.series}
          type="bar"
          style={{ overflow: 'visible' }}
        />
      </div>
      <span></span>
      <span></span>
    </motion.div>
  );
}




export default TwoRowsCard;
