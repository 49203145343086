import "./MainDash.css";
import React, {useEffect} from 'react'
import LeftSide from "./LeftSide";

const MainDash = () => {

  useEffect(() => {
    document.title = 'Dashboard';
  },[])
  
  return (
    <div className="MainDash min-height">
      <div className="AppGlass">        
        <LeftSide/>
      </div>
    </div>
  )
}

export default MainDash