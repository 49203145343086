import React,{ useState, useEffect, useContext } from 'react';
import { MDBContainer, MDBCard, MDBCardBody, MDBRow}from 'mdb-react-ui-kit';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import './KeyByUuid.css';
import {axiosNoAuth} from '../../api/axios';
import DataContext from '../../context/DataContext';
import SnackbarAlerts from '../SnackbarAlerts';
import { enqueueSnackbar } from 'notistack';
import {ErrorMsgs} from '../../Data/Data';

const UUID_REGEX = /^[A-Z0-9]{5,7}-[A-Z0-9]{5,7}-[A-Z0-9]{5,7}-[A-Z0-9]{5,7}$/;
const KEY_BY_UUID = process.env.REACT_APP_API_KEY_OFFLINE_ACTIVATION;
 
function KeyByUuid() {

  const [type, setType] = useState('');
  const [fullKey, setFullKey] = useState('');
  const [deviceName, setDeviceName] = useState('');
  const [ipAddress, setIpAddress] = useState('');
  const [macAddress, setMacAddress] = useState('');
  const [os, setOS] = useState('');
  const [processorType, setProcessorType] = useState('');
  const [ramSize, setRamSize] = useState('');
  const [userName, setUserName] = useState('');
  const [gotKey, setGotKey] = useState(false);
  const [licenseKey, setLicenseKey] = useState('')

  const [uuid, setUuid] = useState('');
  const [validUuid, setValidUuid] = useState(false);
  const [uuidFocus, setUuidFocus] = useState(false);

  const [downloadButton, setDownloadButton] = useState('Download Key')
  const [fileName, setFileName] = useState('');
 // const [sentRequest, setSendRequest] = useState(false)

  const [open, setOpen] = useState(false);
  const [severity, setServerity] = useState('success');
  const [message, setMessage] = useState('');
    
  const {isEmpty} = useContext(DataContext);
  let variant
 
  useEffect(() => {
    document.title = 'Offline Activation';
    const queryString = window.location.search;
    if(queryString){
      const urlParams = new URLSearchParams(queryString);
      const data = urlParams.get('data');
      const decodedData = atob(data);
      //console.log(decodedData);
      const jsonData = JSON.parse(decodedData);
      setType(jsonData.uuid);
      setFullKey(jsonData.fullKey);
      setDeviceName(jsonData.deviceName);
      setIpAddress(jsonData.ipAddress);
      setMacAddress(jsonData.macAddress);
      setOS(jsonData.os);
      setProcessorType(jsonData.processorType);
      setRamSize(jsonData.ramSize);
      setUserName(jsonData.userName);
    }
  }, []);

  //validate uuid
  useEffect(() => {
    setValidUuid(UUID_REGEX.test(uuid));
    if(uuid.includes(' ')){
      setUuid(uuid.trim())
    }
  }, [uuid])

 const handleGetKey = async (e) => {
  e.preventDefault();

  if(!validUuid){
    return;
  }
  
  const uuidSplit = uuid.split("-");
  setFileName(uuidSplit[3]);

  const postData ={uuid, type, fullKey, deviceName, ipAddress, macAddress,
                    os, processorType, ramSize, userName};
  try{
    const response = await axiosNoAuth.post(KEY_BY_UUID, postData);
      const data = response.data;
      const result = response.data.result
      if(data.status){
        if(result){
          setGotKey(true);
          setUuidFocus(false);
          setUuid('')
          setLicenseKey(result.fullKey);
        }else{
          setGotKey(false);
          variant = 'error'
          enqueueSnackbar(data.msg, {variant})
        }
      }else{
        setGotKey(false);
        variant = 'error'
        enqueueSnackbar(data.msg, {variant})
      }
  }catch (err){
    setGotKey(false);
    variant = 'error'
    if (!err.response) {
      enqueueSnackbar(ErrorMsgs[0].noResponse, {variant}) 
    } else if (err.response.status !== 403) {
      enqueueSnackbar(ErrorMsgs[0].fetchError, {variant}) 
    }
  }
}

const handleDownload = () => {
    const fileData = licenseKey
    const element = document.createElement("a");
    const file = new Blob([fileData], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = fileName+"_license.lic";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    setDownloadButton('Key Downloaded')
}

  return (     
    <MDBContainer fluid className='main-con'>
    <SnackbarAlerts 
      open={open} 
      setOpen={setOpen} 
      msg={message} 
      severity={severity}
    />
      <MDBCard className='card-keyUuid p-5' >
        <MDBCardBody className='p-0 '>{/* 
          <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>  */}     
          <MDBRow className='description'>
            <ul className={!gotKey ? '' : 'hide'}> 
              <li>Copy the URL from the system of the PC which there is the software
                that need to be activated. </li>
              <li>There should not be any spaces in UUID</li>
              <li>A UUID should contain four parts which each part should contain 
                minimum five numbers and capital letters</li>
              <p>Ex: XXXXX-XXXXX-XXXXX-XXXXX</p>
            </ul> 
            <ul className={gotKey ? '' : 'hide'}> 
              <li>Download the generated key by clicking on following button.</li>
              <li>Take a copy of downloaded file.</li>
              <p>Ex: Using a pen drive</p>
              <li>Click on activate button of our system in your PC.</li>
              <li>Click on select key button and select the file with license key.</li>              
              <li>Selected key will be shown in the bot.</li>              
              <li>Click on OK button.</li>              
              <li>If it is a valid key a button call "Valid Key" will appear.</li>
              <li>If not please contact customer support.</li>
            </ul>
          </MDBRow>
          <MDBRow>
            <Form onSubmit={handleGetKey} className='text-center mt-4'>                        
                <Form.Group className={!gotKey ? 'mb-3' : 'hide'}>
                    <Form.Label size='sm'>UUID</Form.Label> 
                    <Form.Control 
                        type='text' 
                        id = 'uuidId' 
                        onChange={(e) => setUuid(e.target.value)}
                        value={uuid}
                        required
                        onFocus={() => setUuidFocus(true)}
                        onBlur={() => setUuidFocus(false)}
                    />
                    <p className={uuidFocus && !validUuid && !isEmpty(uuid)? 'validate' :'hide'}>Invalid UUID</p>
                </Form.Group>
                <div className='d-grid'>
                <Button 
                    className={!gotKey ? '' : 'hide'} 
                    onClick={handleGetKey} 
                    variant="primary" 
                    disabled = {!validUuid}
                >
                    Get Key
                </Button>
                <Button 
                    className={gotKey ? '' : 'hide'} 
                    onClick={handleDownload} 
                    variant="primary" 
                >
                    
                {downloadButton}</Button>
                
                </div>    
            </Form>            
          </MDBRow>
      </MDBCardBody>
    </MDBCard>
  </MDBContainer>       
  );
}

export default KeyByUuid;
