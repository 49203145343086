import React, {useState} from 'react'
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';

const SnackbarAlerts = (props) => {

  function TransitionDown(props) {
    return <Slide {...props} direction="down" />;
  }
  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    props.setOpen(false);
  }

  return (
    <div>
        <Stack spacing={2} >
          <Snackbar /* 
            anchorOrigin={{ vertical:'top', horizontal:'center' }} */ 
            open={props.open} 
            autoHideDuration={5000} 
            onClose={handleClose}
            TransitionComponent={TransitionDown}
            >
            <Alert 
              onClose={handleClose} 
              severity={props.severity} 
              variant="filled"
              sx={{ width: '100%' }}
              >
                {props.msg}
            </Alert>
          </Snackbar>
        </Stack>
  </div>
  )
}

export default SnackbarAlerts
