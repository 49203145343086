import React from 'react'
import { Outlet } from "react-router-dom";
import Navigationbar from './NavigationBar/Navgationbar';
import Footer from './Footer/Footer';

import 'react-bootstrap-typeahead/css/Typeahead.css';

const Layout = () => {
  return (
    <React.Fragment>
        <Navigationbar />
        <Outlet />
        <Footer />
    </React.Fragment>
  )
}

export default Layout