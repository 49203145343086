import React, {useState, useEffect, useContext} from 'react'
import { MDBRow, MDBCol, MDBCard, MDBCardBody}from 'mdb-react-ui-kit';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from '../../api/axios';
import SnackbarAlerts from "../SnackbarAlerts";
import DataContext from '../../context/DataContext';
import SearchBar from '../SearchBar';
import {Status, ErrorMsgs} from '../../Data/Data'
import { enqueueSnackbar } from 'notistack';
import DataTable from 'react-data-table-component';
import ConfirmDialog from '../ConfirmDialog';

const COUNTRY_URL = process.env.REACT_APP_API_COUNTRY_FIND_ALL;  
const COUNTRY_BY_ID = process.env.REACT_APP_API_COUNTRY_FIND_BY_ID; 
const COUNTRY_SAVE = process.env.REACT_APP_API_COUNTRY_SAVE; 
const COUNTRY_UPDATE = process.env.REACT_APP_API_COUNTRY_UPDATE; 
const COUNTRY_DELETE = process.env.REACT_APP_API_COUNTRY_DELETE; 

const Country = () => {

  let variant

  const {filteredData, isEmpty} = useContext(DataContext)
  const [dataId, setDataId] = useState(0);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [Msg, setMsg] = useState('');
  const [severity, setServerity] = useState('success');
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [status, setStatus] = useState(''); 
  
  const [openEdit, setOpenEdit] = useState(false)
  const editConfirm = "Are you sure you want to update this field?"
  const [openDelete, setOpenDelete] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState('')
  const [editObj, setEditObj] = useState({})
  
  const columns = [
    {
      name: 'Name',
      selector: row => row.name,
      wrap: true,
    },
    {
      name: 'Code',
      selector: row => row.code,
      wrap: true,
    },
    {
      name: 'Status',
      selector: row => (row.status === true )?'Active':'De-active',
      wrap: true,
    },
    {
      name: 'Actions',
      button: true,
      width: '300px',  
      selector: row => row.vatNo,
      cell: (row) => (
        <div style={{display: 'flex', justifyContent: 'space-between'}}>          
          <Button
            className="edit-button"
            variant="warning"
            onClick={() => actionClick(row.id, 'edit')}
          >
              Edit
          </Button>
          <Button 
            className={row.status === true ? "delete-button" : 'hide'}
            variant="danger"
            onClick={() => enableDisable(row.id, 'disable', row.name, row.code)}
            >
              Disable 
          </Button>
          <Button 
            className={row.status === false ? "success-button" : 'hide'}
            variant="success"
            onClick={() => enableDisable(row.id, 'enable', row.name, row.code)}
            >
              Enable 
          </Button>
        </div>
          
      ),
    },    
  ];

  const customStyles = {
    rows: {
        style: {
            minHeight: '50px',
             // override the row height
        },
    },
    headCells: {
        style: {
          borderStyle: 'solid',
            borderColor: '#40c4ff',
            borderWidth: '1px' ,
            borderCollapse: 'collapse',
            fontWeight: 'bold',
            padding: '5px',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#b2ebf2',
        },
    },
    cells: {
        style: {
            overFlowWrap: 'break-word',
            borderStyle: 'solid',
            borderColor: '#40c4ff',
            borderWidth: '1px' ,
            borderCollapse: 'collapse',
            padding: '5px',
            alignItems: 'center',
            justifyContent: 'center'                
            
        },
    },
};
  

const actionClick = (id, action) => {
  setDataId(id); 
  if(action === 'edit'){
    setOpenEdit(true);
  }
} 

const enableDisable = (id, action, name, code) =>{
  setDataId(id); 
  if(action === 'disable'){
    setOpenDelete(true);
    setConfirmMsg("Are you sure you want to disable this field?")
    setEditObj({id, status:false, name, code})
  }else if(action === 'enable'){
    setOpenDelete(true);
    setConfirmMsg("Are you sure you want to enable this field?")
    setEditObj({id, status:true, name, code})
  }
}

  const handleEnableDisable = async () =>{
    setOpenDelete(false)
    try {
      const response = await axios.put(COUNTRY_UPDATE , editObj);      
      const status = response.data.status;
      const msg = response.data.msg;
      if(status){
        variant = 'success'
        fetchCountry();
        enqueueSnackbar('Updated Successfully!', {variant})
        setDataId(0)
      }else{
        variant = 'error'
        enqueueSnackbar(msg, {variant})
      }
    } catch (err) {
      variant = 'error'
      if(!err.response){
        enqueueSnackbar(ErrorMsgs[0].noResponse, {variant}) 
      }else if(err.response !== 403){
        enqueueSnackbar(ErrorMsgs[0].fetchError, {variant}) 
      }
      setDataId(0)
    }
  }

  //fetch all the countries
  const fetchCountry = async () => {            
    try {
        const response = await axios.get(COUNTRY_URL+`2`);
        const status = response.data.status;
        const result = response.data.result;
        const msg = response.data.msg;
          if(status){
            setData(result);
          }else{    
            variant = 'error'
            enqueueSnackbar(msg, {variant})           
            setData([]);
          }
    } catch (err) {
      variant = 'error'
      if(!err.response){
        enqueueSnackbar(ErrorMsgs[0].noResponse, {variant}) 
      }else if(err.response !== 403){
        enqueueSnackbar(ErrorMsgs[0].fetchError, {variant}) 
      }    
    }
  }

  useEffect(() => {
    fetchCountry();
  },[]);
  
  const fetchCountryById = async () => {    
    setOpenEdit(false);    
    try {
      const response = await axios.get(COUNTRY_BY_ID + `${dataId}`);
      const status = response.data.status;
      const result = response.data.result;
      const msg = response.data.msg;
      if(status){
        setName(result.name);
        setCode(result.code);
        setStatus(result.status);
      }else{
        variant = 'error'
        enqueueSnackbar(msg, {variant})
      }
    } catch (err) {
      variant = 'error'
      if(!err.response){
        enqueueSnackbar(ErrorMsgs[0].noResponse, {variant}) 
      }else if(err.response !== 403){
        enqueueSnackbar(ErrorMsgs[0].fetchError, {variant}) 
      }
    }
  }

  //handle submit method
  const handleSubmit = async(e) => {    
    e.preventDefault();
    if(status === "true"){
      setStatus(true);
    }else if(status === "false"){
      setStatus(false);
    }
    const newCountry = {name, code};
    try {        
      const response = await axios.post(COUNTRY_SAVE, newCountry);
      const status = response.data.status;
      const msg = response.data.msg;   
      if(status){
        variant = 'success'
        enqueueSnackbar('Adding New Country Successful!', {variant})
        setName('')
        setCode('')
        setStatus('')
        fetchCountry();
      }else{
        variant = 'error'
        enqueueSnackbar(msg, {variant})
      }
    } catch (err) {
      variant = 'error'
      if(!err.response){
        enqueueSnackbar(ErrorMsgs[0].noResponse, {variant}) 
      }else if(err.response !== 403){
        enqueueSnackbar(ErrorMsgs[0].fetchError, {variant}) 
      }
    }
  }  

  const handleEdit = async() => {  
    if(status === "true"){
      setStatus(true);
    }else if(status === "false"){
      setStatus(false);
    }
    if(openDelete){
      setOpenDelete(false)
    }
    const updateCountry = {id:dataId, name, code, status};
    try {
      const response = await axios.put(COUNTRY_UPDATE , updateCountry);      
      const status = response.data.status;
      const msg = response.data.msg;
      if(status){
        variant = 'success'
        enqueueSnackbar('Updated Successfully!', {variant})
        setDataId(0)
        setName('')
        setCode('')
        setStatus('')
        fetchCountry();
      }else{
        variant = 'error'
        enqueueSnackbar(msg, {variant})
      }
    } catch (err) {
      variant = 'error'
      if(!err.response){
        enqueueSnackbar(ErrorMsgs[0].noResponse, {variant}) 
      }else if(err.response !== 403){
        enqueueSnackbar(ErrorMsgs[0].fetchError, {variant}) 
      }
      setDataId(0)
    }
  }
  return (
    <> 
    <ConfirmDialog 
      open={openEdit}  
      msg={editConfirm} 
      setOpen={setOpenEdit} 
      onYes={()=>fetchCountryById()}
    />
    <ConfirmDialog 
      open={openDelete}  
      msg={confirmMsg} 
      setOpen={setOpenDelete} 
      onYes={()=>handleEnableDisable()}
    />      
    <SnackbarAlerts 
      open={open} 
      setOpen={setOpen} 
      msg={Msg} 
      severity={severity}
    />
    <MDBCard className='card-table'>
      <MDBCardBody className='p-0'>
        <MDBRow className='bg-blue p-3 mb-2'>
        <Form>
          <MDBRow>
            <MDBCol md='3' className='mb-1 mu-1'>
              <Form.Group className='mb-3' >
                <Form.Label className='white-lable' size='sm'>Name</Form.Label>    
                <span className="mandatory"> *</span>                   
                <Form.Control 
                  type='text'
                  id='nameId'
                  onChange={(e) => setName(e.target.value)}
                  value={name} 
                />            
              </Form.Group>
            </MDBCol>
            <MDBCol md='3' className='mb-1 mu-1'>
              <Form.Group className='mb-3' >
                <Form.Label className='white-lable' size='sm'>Code</Form.Label>    
                <span className="mandatory"> *</span>                   
                <Form.Control 
                  type='text' 
                  id='codeId'
                  onChange={(e) => setCode(e.target.value)}
                  value={code} 
                />            
              </Form.Group>                 
            </MDBCol> 
            <MDBCol md='3'>
              <Form.Group className='mb-3' >
                <Form.Label className='white-lable' size='sm'>Status</Form.Label>    
                <span className="mandatory"> *</span>
                <Form.Select
                  id='statusId'
                  onChange={(e) => setStatus(e.target.value)}
                  value={status}
                >
                  <option disabled value=''>Status...</option>
                  {Status.map((options, index) => (
                  <option key={index} value={options.value}>{options.select}</option>
                  ))}
                </Form.Select>           
              </Form.Group>              
            </MDBCol>              
            <MDBCol md='3' className='mb-3'>              
              <Button 
                className={dataId === 0 ? '' : 'hide-row'}
                style={{ marginTop: '30px', width: '100%'}}
                onClick={handleSubmit}
                disabled={isEmpty(name) || isEmpty(code) || isEmpty(status)}
              >
                Add New
              </Button>  
              <Button 
                className={dataId === 0 ? 'hide-row' : ''}
                style={{ marginTop: '30px', width: '100%'}}
                onClick={handleEdit}
                disabled={isEmpty(name) || isEmpty(code) || isEmpty(status)}
              >
                Edit
              </Button>                
            </MDBCol>                                        
          </MDBRow> 
        </Form>             
        </MDBRow>
        <MDBRow className='bg-white p-3 h-80'>
          <SearchBar  data={data}/>
          <MDBCol md='12'>
            <MDBRow> 
              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                persistTableHead
                responsive
                customStyles={customStyles}
              />            
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
    </>
  )
}

export default Country

