import React,{useState, useContext} from "react";
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from 'react-bootstrap/Button';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import ConfirmDialog from '../ConfirmDialog';
import SnackbarAlerts from "../SnackbarAlerts";
import { enqueueSnackbar } from 'notistack';
import DataContext from '../../context/DataContext';
import { ErrorMsgs } from "../../Data/Data";
import axios from "../../api/axios";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const TableBase = ({ columns, data, deleteURL, fetch}) => {

  let variant = 'success';
  
  const {setDataId} = useContext(DataContext)
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openEdit, setOpenEdit] = useState(false)
  const editConfirm = "Are you sure you want to update this field?"
  const [openDelete, setOpenDelete] = useState(false);
  const deleteConfirm = "Are you sure you want to delete this field?"
  const [id, setId] = useState(0);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFail, setOpenFail] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const successMsg = "Deleted successfully"
  const [severity, setServerity] = useState('success');

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const actionClick = (id, action) => {
    if(action === 'edit'){
      setOpenEdit(true);
    }else if(action === 'delete'){
      setOpenDelete(true);
    }
    setId(id)
  } 

  const handleDelete = async (id) => {  
    setOpenDelete(false);
    try{
      const response = await axios.delete(deleteURL +`${id}`)
        const status = response.data.status;
        const msg = response.data.msg        
       if(status){ 
        fetch();
        setDataId(0)
        variant = 'success'
        enqueueSnackbar('Successfully Deleted!', {variant}) 
       }else{
        variant = 'error'
        enqueueSnackbar(msg, {variant}) 
      }
    }catch (err) {  
      variant = 'error'
      if(!err.response){
        enqueueSnackbar(ErrorMsgs[0].noResponse, {variant}) 
      }else if(err.response !== 403){
        enqueueSnackbar(ErrorMsgs[0].fetchError, {variant}) 
      } 
      setDataId(0)
    }
  };

  const handleEdit = (id) => {
    setDataId(id);
    setOpenEdit(false); 
  }; 
 
  return (
    <>
    <ConfirmDialog 
      open={openEdit}  
      msg={editConfirm} 
      setOpen={setOpenEdit} 
      onYes={()=>handleEdit(id)}
    />
    <ConfirmDialog 
      open={openDelete}  
      msg={deleteConfirm} 
      setOpen={setOpenDelete} 
      onYes={()=>handleDelete(id)}
    />
    <SnackbarAlerts 
      open={openSuccess} 
      setOpen={setOpenSuccess} 
      msg={successMsg} 
      severity={severity}
    />
    <SnackbarAlerts 
      open={openFail} 
      setOpen={setOpenFail} 
      msg={errMsg} 
      severity={severity}
    />
    <TableContainer component={Paper}>
      <div className='p-3'>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell key={column.id}>{column.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : data
          ).map(row => (
            <TableRow key={row.id}>
              {columns.map(column => (
                <TableCell className="py-1 my-1" key={column.id}>
                  {column.id === "actions" ? (
                    <>
                      <Button
                        className="edit-button"
                        variant="warning"
                        onClick={() => actionClick(row.id, 'edit')}
                      >
                          Edit
                      </Button>
                      <Button 
                        className={data.status === true ? "delete-button" : 'hide'}
                        variant="danger"
                        onClick={() => actionClick(row.id, 'delete')}
                        >
                          Disable 
                      </Button>
                      <Button 
                        className={data.status === false ? "success-button" : 'hide'}
                        variant="success"
                        onClick={() => actionClick(row.id, 'delete')}
                        >
                          Enable 
                      </Button>
                    </>
                  ) : column.id === "status" ? (<React.Fragment>{(row[column.id]=== true ? "Active" : "De-active")}</React.Fragment>):(
                    <React.Fragment>{row[column.id]}</React.Fragment>
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={3}
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
      </div>
    </TableContainer>
    </>
  );
};

export default TableBase;


