 import React, { useEffect, useState, useContext } from 'react'
import "./Cards.css";
import axios from '../../../../api/axios';
import {UilClipboardAlt, UilKeySkeleton, UilKeySkeletonAlt} from "@iconscout/react-unicons";
import SnackbarAlerts from '../../../SnackbarAlerts';
import { enqueueSnackbar } from 'notistack';
import {ErrorMsgs} from '../../../../Data/Data'

import KeyCard from "../Card/KeyCard";
import TwoRowsCard from "../Card/TwoRowsCard";

const KEY_WALLET_URL =process.env.REACT_APP_API_GET_KEY_WALLET;
const EXPIRINGKEYS_URL = process.env.REACT_APP_API_GET_EXPIRING_KEYS_NEXT_MONTH;

const Cards = () => {

  let variant

  const [expiringKeys, setExpiringKeys] = useState([{ index : 0,expDate: '', customerName: '', moblieNo: '',tpNo:''}])
  const [expiringKeysCount, setExpiringKeysCount] = useState(0); 

  const [keyWallet, setKeyWallet] = useState(null);
  const [available, setAvailable] = useState(0);
  const [freeAvailable, setFreeAvailable] = useState(0);
  const [used, setUsed] = useState(0);
  const [freeUsed, setFreeUsed] = useState(0);
  const [total, setTotal] = useState(0);
  const [freeTotal, setFreeTotal] = useState(0)
  const [perpetualAvailable, setPerpetualAvailable] = useState(0);
  const [perpetualUsed, setPerpetualUsed] = useState(0);
  const [perpetualTotal, setPerpetualTotal] = useState(0)
  
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [severity, setServerity] = useState('success');  
  // Example data for availableByMonth and usedByMonth


  const fetchKeyWallet = async (value) => {            
    try {
        const response = await axios.get(KEY_WALLET_URL);
        const status = response.data.status
        const result = response.data.result
        const msg = response.data.msg
          if(status){
            if(result){
              setKeyWallet(result)
            }else{
              setKeyWallet([])
            }
          }else{               
            setKeyWallet([])
            variant = 'error'
            enqueueSnackbar(msg, {variant})
          }
    } catch (err) {
      variant = 'error'
      if(!err.response){
        enqueueSnackbar(ErrorMsgs[0].noResponse, {variant}) 
      }else if(err.response.status !== 403){
        enqueueSnackbar(ErrorMsgs[0].fetchError, {variant}) 
      }          
    }
  }

  useEffect(() => {
    fetchKeyWallet()
  }, [])

   //set available and freeAvailable from key wallet
   useEffect(() => {
    if(keyWallet){
      setAvailable(keyWallet.available);
      setFreeAvailable(keyWallet.freeAvailable);
      setUsed(keyWallet.used);
      setFreeUsed(keyWallet.freeUsed);
      setTotal(keyWallet.available + keyWallet.used);
      setFreeTotal(keyWallet.freeAvailable + keyWallet.freeUsed)
      setPerpetualAvailable(keyWallet.perpetualAvailable);
      setPerpetualUsed(keyWallet.perpetualUsed);
      setPerpetualTotal(keyWallet.perpetualAvailable + keyWallet.perpetualUsed);
    }else{      
      setAvailable(0);
      setFreeAvailable(0);
      setUsed(0);
      setFreeUsed(0);
      setPerpetualAvailable(0);
      setPerpetualUsed(0);
    }
  }, [keyWallet])

  useEffect(() => {

    const fetchExpiringkeys = async () => {
        try {
            const response = await axios.get(EXPIRINGKEYS_URL);
              const status = response.data.status
              const result = response.data.result
              const msg = response.data.msg
              if (status) {
                  setExpiringKeys(result);
              } else {// handle the empty data
                  setExpiringKeys([]);
                  variant = 'error'
                  enqueueSnackbar(msg, {variant})
              }
        } catch (err) {
          variant = 'error'
          if(!err.response){
            enqueueSnackbar(ErrorMsgs[0].noResponse, {variant}) 
          }else if(err.response !== 403){
            enqueueSnackbar(ErrorMsgs[0].fetchError, {variant}) 
          }
        }
    }
    fetchExpiringkeys();
}, []);

useEffect(() => {
  setExpiringKeysCount(expiringKeys.length);
}, [expiringKeys]);


  //set totals when values change
  useEffect(() => {
      setTotal(available + used);
      setFreeTotal(freeAvailable + freeUsed) 
      setPerpetualTotal(perpetualAvailable + perpetualUsed)
  }, [available, freeAvailable, used, perpetualAvailable , perpetualUsed, freeUsed, total, freeTotal])

  return (
    <div className="Cards">      
      <div className="parentContainer">
        <KeyCard 
          total= {total}
          used = {used}
          available = {available}
          color = 'purple'
          title = 'Keys'
          icon = {UilKeySkeleton} 
          
        />
      </div> 
      <div className="parentContainer">
        <KeyCard 
          total= {perpetualTotal}
          used = {perpetualUsed}
          available = {perpetualAvailable}
          color = 'green'
          title = 'Perpetual Keys'
          icon = {UilKeySkeleton} 
          
        />
      </div> 
      <div className="parentContainer">
        <KeyCard
          total= {freeTotal}
          used = {freeUsed}
          available = {freeAvailable}
          color = 'pink'
          title = 'Temporary Keys'
          icon = {UilKeySkeletonAlt} 
          
        />
      </div> 
      <div className="parentContainer">
        <TwoRowsCard 
          title = 'Upcoming 31 Days' 
          color = 'purple' 
          icon = {UilClipboardAlt}
          rowOne = {expiringKeysCount} //manual value  
          rowTwo = {0}   
          headingOne = 'Renewals'
          headingTwo = 'Absent keys'
        />
      </div>    
    </div>
  );
};

export default Cards;
