import './Table.css';
import Popover from '@mui/material/Popover';
import axios from '../../api/axios'
import React, { useEffect, useState, useRef, useContext } from 'react';
import { MDBRow, MDBCol, MDBContainer, MDBCard, MDBCardBody } from 'mdb-react-ui-kit';
import { Typeahead } from 'react-bootstrap-typeahead';
import TypeAheadClear from '../TypeAheadClear';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { enqueueSnackbar } from 'notistack';
import { ErrorMsgs, FilterByUsers } from '../../Data/Data'
import DataContext from '../../context/DataContext';
import { GrClose } from 'react-icons/gr';

const SELLER_PROFILES = process.env.REACT_APP_API_SELLER_FIND_ALL_PROFILES;
const CUSTOMER_TRANSFER = process.env.REACT_APP_API_CUSTOMER_TRANSFER;

const TransferPopup = (props) => {

  let variant = 'success';
  const { isEmptyArray, setTransferCustomerId, transferCustomerId } = useContext(DataContext)
  const [customerId, setCustomerId] = useState(0);
  const [fromId, setFromId] = useState(0)
  const [transferTo, setTransferTo] = useState([])
  const [transferToOp, setTransferToOp] = useState([])
  const [toFocus, setToFocus] = useState(false)
  const [noTo, setNoTo] = useState(false);
  const [toId, setToId] = useState(0);
  const toRef = useRef(null)
  const [desc, setDecs] = useState('')

  useEffect(() => {

    const fetchSellers = async () => {
      try {
        const response = await axios.get(SELLER_PROFILES);
        const status = response.data.status;
        const result = response.data.result;
        const msg = response.data.msg;
        //console.log("API ", result);
        if (status) {
          let activeSellers = result.filter(item => item.isDeleted === false);
          //console.log(result.filter(item => item.isDeleted === true))
          setTransferToOp(activeSellers);
        } else {
          variant = 'error'
          enqueueSnackbar(msg, { variant })
        }
      } catch (err) {
        variant = 'error'
        if (!err.response) {
          enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
        } else if (err.response !== 403) {
          enqueueSnackbar(ErrorMsgs[0].fetchError, { variant })
        }
      }
    }

    if (props.open) {
      setCustomerId(props.customerId)
      fetchSellers()
      setFromId(props.fromId)
      setToId(props.toId)
    }
  }, [props.open, props.customerId, props.fromId, props.toId])

  useEffect(() => {
  }, [customerId, transferCustomerId]);

  useEffect(() => {
    if (transferTo.length !== 0) {
      setToId(transferTo[0].sellerID)
      //console.log("Id", toId);
    }
  }, [transferTo]);

  const handleTransfer = async (e) => {
    e.preventDefault();
    const transfer = { oldOwner: fromId, customerId, newOwner: toId, desc }
    try {
      const response = await axios.post(CUSTOMER_TRANSFER, transfer);
      const data = response.data;
      const msg = data.msg


      if (data.status) {
        const TranscustomerId = data.result.id; // Assuming 'id' is the property holding the customerId
        // Set the transferCustomerId
        props.onTransferComplete(TranscustomerId);

        //console.log("transfer Customer ID ", TranscustomerId);
        variant = 'success'
        enqueueSnackbar('Customer Transfer Successful!', { variant })

        props.setOpen(false);
        setToFocus(false)
        setTransferTo([])
        props.fetch()

      } else {
        variant = 'error'
        enqueueSnackbar(msg, { variant })
        return
      }
    } catch (err) {
      variant = 'error'
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
      } else if (err.response !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant })
      }
    }
  }

  useEffect(() => {
    if (transferTo.length !== 0) {
      setToId(transferTo[0].sellerID)
      //console.log("Id", toId);
    }
  }, [transferTo])

  

  //check whether transfer to is empty for validation
  const validateTo = () => {
    if (noTo) {
      return true
    } else {
      return false
    }
  }

  const handleClose = (reason) => {
    setToFocus(false)
    setTransferTo([])
    setDecs('')
    props.setOpen(false);
    if (reason === 'clickaway') {
      return;
    }
  }

  return (
    <div className={props.open ? 'glass-effect' : ''}>
      <Popover
        open={props.open}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: window.innerHeight / 2, left: window.innerWidth / 2 }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <div className='table-div'>
          <div className='end-div p-0'>
            <GrClose className='close-button' onClick={handleClose} />
          </div>
          <div className='d-flex justify-content-center'>
            <MDBCol md='12' className='bg-white p-4 popup-style m-1'>
              <Form>
                <MDBRow>
                  <fieldset
                    disabled={true}
                    className={'fieldset-invisible'}
                  >
                    <Form.Group className={'mb-3'} >
                      <Form.Label className='' size='sm'>Transfer From</Form.Label>
                      <span className="mandatory"> *</span>
                      <Form.Control
                        id="fromId"
                        onChange={() => props.setTransferFrom()}
                        value={props.transferFrom}
                      />
                    </Form.Group>
                    <Form.Group className='mb-3' >
                      <Form.Label className='' size='sm'>Customer</Form.Label>
                      <span className="mandatory"> *</span>
                      <Form.Control
                        id="fromId"
                        onChange={() => props.setCustomer()}
                        value={props.customer}
                      />
                    </Form.Group>
                  </fieldset>
                  <Form.Group className='mb-3' >
                    <Form.Label className='' size='sm'>Transfer To</Form.Label>
                    <span className="mandatory"> *</span>
                    <Typeahead
                      id="customersId"
                      labelKey={(seller) => `${seller.fname} ${seller.lname} (${seller.email})`}
                      onChange={(selected) => {
                        // Assuming selected is an array, you can handle multiple selections if needed
                        if (selected.length > 0) {
                          const selectedSellerId = selected[0].sellerID;
                          setTransferTo(selected);
                          setToId(selectedSellerId);
                          //console.log("Selected SellerID:", selectedSellerId);
                        } else {
                          // Handle the case where no seller is selected
                          setTransferTo([]);
                          setToId(0); // or any default value
                        }
                      }}
                      options={transferToOp}
                      placeholder="User..."
                      selected={transferTo}
                      value={transferTo}
                      inputProps={{ required: true }}
                      ref={toRef}
                      onFocus={() => setToFocus(true)}
                      onBlur={validateTo}
                      style={{ display: 'flex' }}
                    >
                      <TypeAheadClear select={transferTo} setSelect={setTransferTo} />
                    </Typeahead>
                    <p className={transferTo.length === 0 && toFocus ? "validate" : "offscreen"}>
                      Select a valid user.<br />
                    </p>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      id="fromId"
                      onChange={(e) => setDecs(e.target.value)} // Pass the event value to props.setDecs
                      value={desc}
                    />
                  </Form.Group>
                  <MDBRow className='m-0'>
                    <MDBCol md='12' className='d-flex justify-content-end m-0 p-0'>
                      <Button
                        onClick={handleTransfer}
                        disabled={isEmptyArray(transferTo)}
                      >
                        Transfer Customer
                      </Button>
                    </MDBCol>
                  </MDBRow>
                </MDBRow>
              </Form>
            </MDBCol>
          </div>
        </div>
      </Popover>
    </div>
  );
}

export default TransferPopup