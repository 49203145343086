import './Table.css';
import UserTable from './UserTable';
import { MDBRow, MDBCol, MDBContainer, MDBCard, MDBCardBody}from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SnackbarAlerts from '../SnackbarAlerts';
import DistributorStockReport from './DistributorStockReport';

function DistributorReport() {
  
  const location = useLocation()
  const [open, setOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [severity, setSeverity] = useState('error');
  const [tabpanelHeight, setTabpanelHeight] = useState('auto');
  const state = location.state;

  useEffect(() => {
    document.title = 'Distributor Stock Report';
  }, []);

  useEffect(() => {
    const tabpanel = document.getElementById('simple-tabpanel-0');
    const contentHeight = tabpanel.offsetHeight;
    setTabpanelHeight(contentHeight);
  }, []);
  
  return (
    <MDBContainer fluid className='Tcontainer-style min-height'>
      <MDBRow className='d-flex justify-content-center align-items-center h-auto' style={{ width: '82%', height: '913px' }}>
        <MDBCol col='12' className='my-5' >          
          <MDBCard className='card-table'>
            <MDBCardBody className='p-0'>
              <MDBRow className='bg-white pt-3 p-2 h-80'>
                <MDBCol md='12'>
                  <SnackbarAlerts open={open} setOpen={setOpen} msg={alertMsg} severity={severity}/>
                  <DistributorStockReport />
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
    

  );
}

export default DistributorReport;
