import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
//import { useState, useEffect } from 'react';

const DialogYesNo = (props) => {

  return (
    <div>
      <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.onBack()} color="primary" autoFocus>
            Back
          </Button><Button onClick={() => props.onExtend()} color="primary" autoFocus>
            Extend
          </Button>
          <Button onClick={() => props.onView()} color="primary" autoFocus>
            View
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DialogYesNo
