import axios from "../../api/axios";
import React, { useEffect, useMemo, useState, useContext, useRef } from "react";
import DataTable from 'react-data-table-component';
import { useNavigate, useLocation } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import Button from 'react-bootstrap/Button';
import ConfirmDialog from "../ConfirmDialog";
import SnackbarAlerts from "../SnackbarAlerts";
import AddKeyPopup from "./AddKeyPopup";
import { TablesFilterCU, FilterByUsers } from "../../Data/Data";
import { enqueueSnackbar } from 'notistack';
import { ErrorMsgs } from '../../Data/Data';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { RiSettings3Line } from 'react-icons/ri';
import View from '../KeyGenerate/View';
import DataContext from '../../context/DataContext';
import AuthProvider from '../../context/AuthProvider';
import KeyGenData from '../../context/KeyGenData';
import "./UserTable.css";
import Autocomplete from '@mui/material/Autocomplete';
import * as moment from 'moment';
import TextField from '@mui/material/TextField';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import * as RB from 'react-bootstrap';

//import DatePicker from 'react-datepicker'; 

const USER_URL = process.env.REACT_APP_API_SELLER_FIND_ALL_SELLERS;
const SELLER_KEYS = process.env.REACT_APP_API_SELLER_RELATED_KEY_DETAILS;

const ResellerStockReport = () => {
  let variant;

  const location = useLocation();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
 // const [userId, setUserId] = useState(0);
  //const [openAlert, setOpenAlert] = useState(false);
  //const [alertMsg, setAlertMsg] = useState('');
  //const [openEdit, setOpenEdit] = useState(false);
  //const editConfirm = "Are you sure you want to update this user?";
  //const [openDelete, setOpenDelete] = useState(false);
  //const [openView, setOpenView] = useState(false);
  //const [sellers, setSellers] = useState([]);
 // const deleteConfirm = "Are you sure you want to delete this user?";

  const { setActionView, actionView, nameSearch, setSellerSearch, setUserSearch, activeKeyGenIdSeller, setActiveKeyGenIdSeller, setDisabledKeyGenIdSeller, disabledKeyGenIdSeller, userType, setDataId } = useContext(DataContext);
  const { addKeyAccess, partnerAccess, hasCreatePartnerPermission, extendKeyAccess, setHasCreatePartnerPermission, isKeyDeleted, setIsKeyDeleted, reSellerReportPrint } = useContext(AuthProvider);
  const { setValue, value } = useContext(KeyGenData);

  //const [severity, setServerity] = useState('success');
 // const [openAddKey, setOpenAddKey] = useState(false);
  //const [customerName, setCustomerName] = useState('');
  const [searchBy, setSearchBy] = useState('');
  //const [filteredItems, setFilteredItems] = useState([]);
  const [type, setType] = useState(0);
  //const [sentRequest, setSendRequest] = useState(false);
  const [sellerId, setSellerId] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [sellerDetails, setSellerDetails] = useState({});
  const [selectedSeller, setSelectedSeller] = useState(null);
  const [openSearch, setOpenSearch] = useState(false);
  const [availableSellers, setAvailableSellers] = useState([]);
  const autocompleteRef = useRef(null);



  const columns = [
    {
      name: 'Distributor Name',
      selector: row => row.leaderName,
      wrap: true,
      width: '200px',
    },
    {
      name: 'Seller Name',
      selector: row => row.sellerName,
      wrap: true,
      width: '200px',
    },
    {
      name: 'Available Permanent Key',
      selector: row => row.avPrimKeys,
      wrap: true,
      width: '145px',
    },
    {
      name: 'Available Trial Key',
      selector: row => row.avTempKeys,
      width: '145px',
      wrap: true,
    },
    {
      name: 'Available Perpetual Key',
      selector: row => row.avPerpetualKeys,
      width: '145px',
      wrap: true,
    },
    {
      name: 'Installed Trial Key',
      selector: row => row.crTempKeys,
      wrap: true,
      width: '150px',
    },
    {
      name: 'Installed Permanent Key',
      selector: row => row.crPrimKeys,
      width: '150px',
      wrap: true,
    },
    {
      name: 'Installed Perpetual Key',
      selector: row => row.crPerpetualKeys,
      width: '150px',
      wrap: true,
    },

    {
      name: 'Active Permanent Key',
      selector: row => row.acPrimKeys,
      width: '145px',
      wrap: true,
    },
    {
      name: 'Active Trial Key',
      selector: row => row.acTempKeys,
      width: '145px',
      wrap: true,

    },
    {
      name: 'Active Perpetual Key',
      selector: row => row.acPerpetualKeys,
      width: '145px',
      wrap: true,
    },


  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: '60px', // override the row height
      },
    },
    headCells: {
      style: {
        borderStyle: 'solid',
        borderColor: '#40c4ff',
        borderWidth: '1px',
        borderCollapse: 'collapse',
        fontWeight: 'bold',
        padding: '5px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#b2ebf2',
      },
    },
    cells: {
      style: {
        overFlowWrap: 'break-word',
        borderStyle: 'solid',
        borderColor: '#40c4ff',
        borderWidth: '1px',
        borderCollapse: 'collapse',
        padding: '5px',
        alignItems: 'center',
        justifyContent: 'center'
      },
    },
  };



  const fetchUsers = async (type, filter) => {
    try {
      const response = await axios.get(USER_URL + `/1`, {
        params: { search: filter }
      });
      const status = response.data.status;
      const result = response.data.result;
      const msg = response.data.msg;

      if (status) {
        if (result) {
          setUsers(result);
          //console.log(result)
          setAvailableSellers(result.map(user => `${user.fname} ${user.lname}`));

          setHasCreatePartnerPermission(true);
        } else {
          setUsers(result);
          setHasCreatePartnerPermission(false);
        }
      } else {
        setUsers([]);
        variant = 'error';
        enqueueSnackbar(msg, { variant });
      }
    } catch (err) {
      variant = 'error';
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant });
      } else if (err.response !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant });
      }
    }
  };



  const fetchSellerDetails = async (sellerId, fromDate, toDate) => {
    try {
      const formattedFromDate = moment(fromDate).format('YYYY-MM-DD');
      const formattedToDate = moment(toDate).format('YYYY-MM-DD');

      const response = await axios.get(SELLER_KEYS + `${sellerId}/${formattedFromDate}/${formattedToDate}`);
      const { status, result, msg } = response.data;
      if (status) {
        const { sellerName } = result; // Extract the sellerName from the result
        //console.log('Seller Name:', sellerName);
        //console.log('Seller Details:', result);
        setSellerDetails(result);
        return result;
      } else {
        throw new Error(msg);
      }
    
    } catch (error) {
      console.error('Error fetching seller details:', error.message);
      throw new Error(error.message); // Re-throw the error to handle it in the component
    }



    
  };








  const userData = useMemo(() => [...users], [users]);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);


 
  useEffect(() => {
    if (filterText) {
      fetchUsers(type, filterText);

      //console.log('Users', users);
    } else {
      setUsers([]);
    }
  }, [filterText, type]);

 /*  useEffect(() => {
    if (filterText) {
      fetchSellerDetails(sellerId, fromDate, toDate)
        .then(sellerPrintDetails => {
          console.log('Seller Details:', sellerDetails);
          // Do something with the sellerDetails, such as setting state
        })
        .catch(error => {
          console.error('Error fetching seller details:', error.message);
        });
    }
  }, [filterText, sellerId, fromDate, toDate]); */



  /*  useEffect(() => {
     if (filterText === '') {
       setFilteredItems(users);
       return;
     }
 
     const lowercasedFilter = filterText.toLowerCase();
 
     // Filter based on the combined first and last names
     const filteredUsers = users.filter(item => {
       const fullName = `${item.fname} ${item.lname}`.toLowerCase();
       return fullName.includes(lowercasedFilter);
     });
 
     // If the filtered users exist, update the seller ID
     if (filteredUsers.length > 0) {
       setSellerId(filteredUsers[0].id); // Assuming 'id' is the field for seller ID
     }
 
     setFilteredItems(filteredUsers);
   }, [filterText, users]); */

 


  useEffect(() => {
    // Reset autocomplete input field when `selectedSeller` is updated
    if (autocompleteRef.current) {
      autocompleteRef.current.value = '';
    }
  }, [selectedSeller]);


  useEffect(() => {
    // This effect runs after the first effect, so filteredItems should be updated
    //console.log('Seller ID:', sellerId);
  }, [sellerId]);


  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
        setFromDate('');
        setToDate('');

      }

    };

    //console.log('FromDate', fromDate);
    //console.log('toDate', toDate);

    const handleDateClear = () => {

      setToDate(null);
      setFromDate(null);
      setFilterText('');
      setOpenSearch(false)
      setSellerId(null);
      
      
      setSellerDetails({});
      
     
      
    };

    const handleSearchClear = () => {
      setOpenSearch(false)
      if (autocompleteRef.current) {
        autocompleteRef.current.value = ''; // Clear the Autocomplete field
      }
    };

    

    
/* 
    const handleSearch = async () => {
      // Make API call to fetch data based on search criteria
      const lowercasedFilter = filterText.toLowerCase();
    
      // Filter based on the combined first and last names
      const filteredUsers = users.filter(item => {
        const fullName = `${item.fname} ${item.lname}`.toLowerCase();
        return fullName.includes(lowercasedFilter);
      });
    
      // If the filtered users exist, update the seller ID and set seller details
      if (filteredUsers.length > 0) {
        const selectedSellerId = filteredUsers[0].id; // Assuming 'id' is the field for seller ID
        setSellerId(selectedSellerId);
        const sellerDetails = await fetchSellerDetails(selectedSellerId, fromDate, toDate);
        setSellerDetails(sellerDetails);
        setOpenSearch(true);
    
        // Once the data is loaded, trigger the printing process
        //handlePrint();
      } else {
        // If no users match the filter, reset sellerId and sellerDetails
        setSellerId(null);
        setSellerDetails({});
        setOpenSearch(false);
      }
    };
     */

    
  

    const handleSearch = async () => {
      // Make API call to fetch data based on search criteria
      const lowercasedFilter = filterText.toLowerCase();
    
      // Filter based on the combined first and last names
      const filteredUsers = users.filter(item => {
        const fullName = `${item.fname} ${item.lname}`.toLowerCase();
        return fullName.includes(lowercasedFilter);
      });
    
      // If the filtered users exist, update the seller ID and set seller details
      if (filteredUsers.length > 0) {
        const selectedSellerId = filteredUsers[0].id; // Assuming 'id' is the field for seller ID
        setSellerId(selectedSellerId);
        try {
        const sellerDetails = await fetchSellerDetails(selectedSellerId, fromDate, toDate);
        setSellerDetails(sellerDetails);
        setOpenSearch(true);
        } catch (error) {
          setSellerId(null);
          setSellerDetails({});
          setOpenSearch(false);
          enqueueSnackbar('No data found for this seller', { variant: 'error' }); // Display the error message
        }
      } else {
        // If no users match the filter, reset sellerId and sellerDetails
        setSellerId(null);
        setSellerDetails({});
        setOpenSearch(false);
      }
    };
    

    const handlePrint = async () => {
      if (!sellerId && filterText === '') {
        enqueueSnackbar('No seller selected for printing', { variant: 'error' });
        return;
      }

      // Fetch seller details
      const fetchedSellerDetails = await fetchSellerDetails(sellerId, fromDate, toDate);

      // Open a new window for printing
      const printWindow = window.open('', '_blank', 'width=1000,height=800');
      if (printWindow) {
        // Generate printable content
        let sellerDetailsContent = '';
        // Check if sellerDetails is an array
        if (Array.isArray(fetchedSellerDetails)) {
          // If sellerDetails is an array, map over it
          sellerDetailsContent = fetchedSellerDetails.map(seller => `
            <tr>
              <td>${seller.distributorName || ''}</td>
              <td>${seller.resellerName || ''}</td>
              <td>${seller.availableLic || ''}</td>
              <td>${seller.trialLicInstalled || ''}</td>
              <td>${seller.trialLicActive || ''}</td>
              <td>${seller.permanentLicInstalled || ''}</td>
            </tr>
          `).join('');
        } else if (typeof fetchedSellerDetails === 'object' && fetchedSellerDetails !== null) {
          // If sellerDetails is an object, create a single row
          sellerDetailsContent = `
            <tr>
              <td>${fetchedSellerDetails.leaderName || 'N/A'}</td>
              <td>
                
                  ${fetchedSellerDetails.sellerName || 'N/A'}
                
              </td>
              <td>${fetchedSellerDetails.avPrimKeys || '0'}</td>
              <td>${fetchedSellerDetails.avPerpetualKeys || '0'}</td>
              <td>${fetchedSellerDetails.avTempKeys || '0'}</td>
              <td>${fetchedSellerDetails.crTempKeys || '0'}</td>
              <td>${fetchedSellerDetails.acTempKeys || '0'}</td>
              <td>${fetchedSellerDetails.crPrimKeys || '0'}</td>
              <td>${fetchedSellerDetails.crPerpetualKeys || '0'}</td>
            </tr>
          `;
        }

        const content = `
          <!DOCTYPE html>
          <html lang="en">
          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Reseller-wise Stock Activation Report</title>
            <style>
              /* Define styles for the printable document */
              body {
                font-family: Arial, sans-serif;
                margin: 0;
                padding: 0;
              }
              .container {
                width: 100%;
                padding: 20px;
              }
              h1 {
                font-size: 24px;
                font-weight: bold;
                margin-bottom: 20px;
              }
              table {
                width: 100%;
                border-collapse: collapse;
              }
              th, td {
                border: 1px solid #ccc;
                padding: 8px;
                text-align: left;
              }
              th {
                background-color: #f2f2f2;
              }
            </style>
          </head>
          <body>
            <div class="container">
              <h1>Reseller-wise Stock Activation Report</h1>
              <p>Re-seller Name: ${fetchedSellerDetails.sellerName || 'N/A'}</p>
              <p>Period: From <span id="fromDate">${fromDate || 'N/A'}</span> To <span id="toDate">${toDate || 'N/A'}</span></p>
              <table>
                <thead>
                  <tr>
                    <th>Name of Distributor</th>
                    <th>Name of Reseller</th>
                    <th>No. of Permanent Lic Available</th>
                    <th>No. of Perpetual Lic Available</th>
                    <th>No. of Trial Lic Available</th>
                    <th>No. of Trial Lic Installed</th>
                    <th>No. of Trial Lic Still active</th>
                    <th>No. of Permanent Lic Installed</th>
                    <th>No. of Perpetual Lic Installed</th>
                  </tr>
                </thead>
                <tbody id="reportBody">
                  ${sellerDetailsContent}
                </tbody>
              </table>
            </div>
          </body>
          </html>
        `;

        // Write content to the print window
        printWindow.document.open();
        printWindow.document.write(content);
        printWindow.document.close();
        // Trigger the printing process
        printWindow.print();
        // Close the print window after printing (optional)
        printWindow.close();
      } else {
        console.error('Failed to open print window');
      }
    };



    return (
      <MDBCol md='12'>
        <MDBRow className='bg-blue p-3 mb-2'>
          <MDBCol md='3' className='mb-1'>
            <MDBRow>
              <Autocomplete
                freeSolo
                id="search"
                options={availableSellers.map(seller => ({ label: seller, value: seller }))}
                getOptionLabel={(option) => option.label}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setFilterText(newValue.label);
                    const selectedSeller = users.find(user => `${user.fname} ${user.lname}` === newValue.label);
                    if (selectedSeller) {
                      setSellerId(selectedSeller.id);
                    }
                  } else {
                    setFilterText('');
                    setSellerId(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="[Seller name]"
                    variant="outlined"
                    onChange={(e) => setFilterText(e.target.value)}
                    inputRef={autocompleteRef}
                  />
                )}
              />
            </MDBRow>
          </MDBCol>
          <MDBCol md='2' className='mb-1 divider-right'>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="From Date"
                value={fromDate}
                onChange={setFromDate}
                renderInput={params => <TextField {...params} />}
              />
            </LocalizationProvider>
          </MDBCol>
          <MDBCol md='2' className='mb-1'>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="To Date"
                value={toDate}
                maxDate={toDate}
                onChange={setToDate}
                renderInput={params => <TextField {...params} />}
              />
            </LocalizationProvider>
          </MDBCol>
          <MDBCol xs='1' md='1' className='ms-0 ps-2 align-self-center'>
            <RB.Button variant="outline-danger" id="clear-button" type="button" onClick={handleDateClear} style={{ padding: '14px 18px', fontSize: '16px' }}>
              Clear
            </RB.Button>
          </MDBCol>
          <MDBCol xs='1' md='1' className='ms-0 ps-2 align-self-center'>
            <RB.Button id="search-button" type="button" onClick={handleSearch} style={{ padding: '14px 18px', fontSize: '16px' }}>
              Search
            </RB.Button>
          </MDBCol>
          { openSearch && (
            <MDBCol xs='1' md='1' className='ms-0 ps-2 align-self-center'>
              {reSellerReportPrint && (
                <Button id="print-button" type="button" onClick={handlePrint} style={{ width: '92px', padding: '14px 18px', fontSize: '16px' , marginLeft: '50px'}}>
                  Print
                </Button>
              )}
            </MDBCol>
          )}
        </MDBRow>
      </MDBCol>
    );
    
  }, [filterText, resetPaginationToggle, navigate, openSearch, searchBy, type, toDate, fromDate]);

  return (
    <>

      <DataTable
        columns={columns}
        data={[sellerDetails]} // Pass sellerDetails as data instead of filteredItems
        pagination={false} // Disable pagination since there's only one record
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
        responsive
        customStyles={customStyles}
      />

    </>
  );
}

export default ResellerStockReport;
