import React, { useState }  from 'react';
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { Login } from '@mui/icons-material';

const RequireAuth = () => {
    const { auth } = useAuth();
    const location = useLocation();
    const userToken = sessionStorage.getItem('token')

    return (
       userToken? <Outlet />: <Navigate to="/" state={{ from: location }} replace />
       //?<Outlet /> : <Login />
    );
}

export default RequireAuth;