import React from 'react'
import Button from 'react-bootstrap/Button';

const TypeAheadClear = (props) => {

  const styles = {
    customStyle: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0
    }
  }

  const onClear =() => {
    props.setSelect([]);
  }
  return (
    <div>
      {!!(props.select).length &&
        <Button 
          onClick={onClear}
          variant="light"
          >x</Button>}
    </div>
  )
}

export default TypeAheadClear